import React from 'react'
import ReactDOM from 'react-dom/client'
import { HelmetProvider } from 'react-helmet-async'

// import reportWebVitals from './reportWebVitals'
import {
  createBrowserRouter,
  Outlet,
  RouterProvider,
  ScrollRestoration,
} from 'react-router-dom'
import { theme } from './style/theme'
import { ThemeProvider } from '@emotion/react'
import { CssBaseline } from '@mui/material'
import { FragmentGeneratorProvider } from './utility/FragmentGenerator'
import { PostPageWithData } from './components/routes/PostPageWithData'
import { PageWithData } from './components/routes/PageWithData'
import { DefaultRoute } from './components/routes/DefaultRoute'
import { VanityPathMatcher } from './components/routes/VanityPathMatcher'

// Import lazily because aws-rum is a big dependency
import('./utility/appMonitor').then(({ initAppMonitor }) => initAppMonitor())

// Redirect to www. if on root
if (window.location.hostname.match(/^[^.]+\.[^.]+$/)) {
  window.location.href = `https://www.${window.location.hostname}${window.location.pathname}`
}

const Root = () => (
  <>
    <Outlet />
    <VanityPathMatcher />
    <ScrollRestoration />
  </>
)

const router = createBrowserRouter(
  [
    {
      element: <Root />,
      children: [
        {
          path: '/:slug?/*',
          element: <PageWithData />,
        },
        {
          path: '/post/:idOrSlug/*',
          element: <PostPageWithData />,
        },
        {
          path: '*',
          element: <DefaultRoute />,
        },
      ],
    },
  ],
  { future: { v7_relativeSplatPath: true } },
)

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <HelmetProvider>
      <ThemeProvider theme={theme}>
        <FragmentGeneratorProvider>
          <RouterProvider router={router} />
          <CssBaseline />
        </FragmentGeneratorProvider>
      </ThemeProvider>
    </HelmetProvider>
  </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals()
