import React from 'react'
import { alpha, css, Dialog, IconButton } from '@mui/material'
import { ApiFile } from '@benkrejci/shared/src/api/api'
import { getButtonLinkProps } from '../../utility/Link'
import { useNavigate } from 'react-router-dom'
import { Close } from '@mui/icons-material'
import * as rooks from 'rooks'

export const MediaModal = ({
  media,
  ...props
}: {
  media: ApiFile | string | undefined
  nextHref?: string
  onNext?: () => void
  previousHref?: string
  onPrevious?: () => void
} & (
  | {
      closeHref: string
    }
  | { onClose: () => void }
)) => {
  // Lazy load because of big dependencies
  const MediaModalInner = React.lazy(() => import('./MediaModalInner'))
  const navigate = useNavigate()

  rooks.useKey(['ArrowLeft', 'ArrowRight'], (e) => {
    if (e.ctrlKey || e.metaKey || e.shiftKey || e.altKey) return
    if (e.key === 'ArrowRight') {
      if (props.onNext !== undefined) {
        props.onNext()
      } else if (props.nextHref !== undefined) {
        navigate(props.nextHref, { preventScrollReset: true })
      }
    } else if (e.key === 'ArrowLeft') {
      if (props.onPrevious !== undefined) {
        props.onPrevious()
      } else if (props.previousHref !== undefined) {
        navigate(props.previousHref, { preventScrollReset: true })
      }
    }
  })

  return (
    <Dialog
      open={media !== undefined}
      onClose={
        'onClose' in props
          ? props.onClose
          : () => navigate(props.closeHref, { preventScrollReset: true })
      }
      PaperComponent={({ children }) => <div>{children}</div>}
      css={(theme) => css`
        .MuiBackdrop-root {
          background-color: ${alpha(theme.palette.common.black, 0.8)};
        }
      `}
    >
      <IconButton
        {...('onClose' in props
          ? { onClick: props.onClose }
          : getButtonLinkProps(props.closeHref, { preventScrollReset: true }))}
        css={(theme) => css`
          position: absolute;
          top: ${theme.spacing(0.5)};
          right: ${theme.spacing(0.5)};
          z-index: ${theme.zIndex.modal + 1};
          background-color: ${alpha(theme.palette.common.black, 0.25)};
          color: ${theme.palette.getContrastText(theme.palette.common.black)};
          z-index: ${theme.zIndex.modal + 10};
        `}
      >
        <Close fontSize="large" />
      </IconButton>
      {media === undefined ? null : (
        <React.Suspense>
          <MediaModalInner media={media} {...props} />
        </React.Suspense>
      )}
    </Dialog>
  )
}
