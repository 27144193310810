import { ApiPosts } from "@benkrejci/shared/src/api/api";
import { usePosts } from "./usePosts";
import { DataLoader } from "../DataLoader";
import { PostListInner } from "./PostList";
import { PostGridInner } from "./PostGrid";
import { staticAssertNever } from "@benkrejci/shared/src/utilityTypes";
import { PostMasonryInner } from "./PostMasonryInner";

export const Posts = ({ widget }: { widget: ApiPosts }) => {
  const postsFetchState = usePosts(widget.allPosts, [{ field: widget.sortBy, isAsc: widget.sortDirection === 'ascending' }])

  const InnerComponent = (() => {
    switch (widget.displayType) {
      case 'list':
        return PostListInner
      case 'grid':
        return PostGridInner
      case 'masonry':
        return PostMasonryInner
      case 'carousel':
        throw new Error('Carousel not implemented')
      default:
        return staticAssertNever(widget.displayType)
    }
  })()

  if (widget.allPosts) {
    return (
      <DataLoader fetchState={postsFetchState} showSpinner={false}>
        {(posts) => (
          <InnerComponent
            isLoading={postsFetchState.isLoading}
            widget={widget}
            posts={posts}
          />
        )}
      </DataLoader>
    )
  }
  return <InnerComponent isLoading={false} widget={widget} posts={widget.posts ?? []} />
}