import React from 'react'
import { css } from '@emotion/react'
import { CircularProgress, Grid } from '@mui/material'

import { ApiFile } from '@benkrejci/shared/dist/api/api'
import { MediaModal } from './widgets/MediaModal'

export const Media = ({
  width,
  height,
  maxWidth = '100%',
  maxHeight = '40vh',
  minWidth = '40px',
  minHeight = '40px',
  aspectRatio,
  align = 'center',
  imageFit = 'contain',
  className,
  autoPlay,
  controls = true,
  muted = false,
  lazyLoad = true,
  showFormat = 'large',
  enableModal = false,
  render = ({ children }: { children: React.ReactNode }) => children,
  onClick,
  ...fileSrc
}: (
  | {
      file: ApiFile
    }
  | { src: string }
) & {
  width?: string | number
  height?: string | number
  maxWidth?: string | number | null
  maxHeight?: string | number | null
  minWidth?: string | number
  minHeight?: string | number
  aspectRatio?: number
  align?: 'center' | 'left' | 'right'
  imageFit?: 'contain' | 'cover' | 'fill' | 'scale-down'
  showFormat?: 'original' | 'thumbnail' | 'small' | 'medium' | 'large'
  className?: string
  autoPlay?: boolean
  controls?: boolean
  muted?: boolean
  lazyLoad?: boolean
  enableModal?: boolean
  render?: (props: { children: React.ReactNode }) => React.ReactNode
  onClick?: React.MouseEventHandler
}) => {
  const file = 'file' in fileSrc ? fileSrc.file : undefined
  const src =
    'src' in fileSrc
      ? fileSrc.src
      : (showFormat === 'original' ? undefined : file?.formats?.[showFormat]?.url) ??
        file?.url
  const type = file?.mime.match(/^[^/]*/)![0] ?? 'image'

  const [isLoading, setIsLoading] = React.useState(true)
  const ref = React.useRef<HTMLImageElement | HTMLVideoElement>(null)

  React.useEffect(() => {
    if (ref.current && 'complete' in ref.current && ref.current?.complete) {
      setIsLoading(false)
    }
  }, [])

  const [modalIsOpen, setModalIsOpen] = React.useState(false)

  return (
    <>
      {enableModal ? (
        <MediaModal
          media={modalIsOpen ? file ?? src : undefined}
          onClose={() => setModalIsOpen(false)}
        />
      ) : null}
      <Grid
        container
        alignItems={'center'}
        justifyContent={
          align === 'left' ? 'flex-start' : align === 'right' ? 'flex-end' : 'center'
        }
        className={className}
        onClick={(event) => {
          if (enableModal) {
            setModalIsOpen(true)
          }
          onClick?.(event)
        }}
        css={(theme) => css`
          background-color: ${theme.palette.background.paperSecondary ??
          theme.palette.background.paper};
          position: relative;
          min-width: ${minWidth}${typeof minWidth === 'number' ? 'px' : ''};
          min-height: ${minHeight}${typeof minHeight === 'number' ? 'px' : ''};
          width: ${width}${typeof width === 'number' ? 'px' : ''};
          height: ${height}${typeof height === 'number' ? 'px' : ''};
          border-radius: ${theme.shape.borderRadius}px;
          overflow: hidden;
          cursor: ${enableModal ? 'pointer' : 'inherit'};
        `}
      >
        {render({
          children: (
            <>
              {type === 'image' ? (
                <img
                  src={src}
                  alt={file?.alternativeText}
                  onLoad={() => setIsLoading(false)}
                  onError={() => setIsLoading(false)}
                  loading={lazyLoad ? 'lazy' : 'eager'}
                  ref={ref as React.RefObject<HTMLImageElement>}
                  css={(theme) => css`
                    display: block;
                    aspect-ratio: ${aspectRatio};
                    object-fit: ${imageFit ?? 'contain'};
                    max-width: ${maxWidth}${typeof maxWidth === 'number' ? 'px' : ''};
                    max-height: ${maxHeight}${typeof maxHeight === 'number' ? 'px' : ''};
                    height: ${height}${typeof height === 'number' ? 'px' : ''};
                    min-width: ${minWidth}${typeof minWidth === 'number' ? 'px' : ''};
                    min-height: ${minHeight}${typeof minHeight === 'number' ? 'px' : ''};
                  `}
                />
              ) : type === 'video' ? (
                <video
                  controls={controls}
                  autoPlay={autoPlay}
                  muted={muted}
                  loop
                  onCanPlay={() => setIsLoading(false)}
                  onError={() => setIsLoading(false)}
                  onLoad={() => setIsLoading(false)}
                  ref={ref as React.RefObject<HTMLVideoElement>}
                  css={css`
                    display: block;
                    aspect-ratio: ${aspectRatio};
                    object-fit: ${imageFit ?? 'cover'};
                    width: ${width}${typeof width === 'number' ? 'px' : ''};
                    height: ${height}${typeof height === 'number' ? 'px' : ''};
                    max-width: ${maxWidth}${typeof maxWidth === 'number' ? 'px' : ''};
                    max-height: ${maxHeight}${typeof maxHeight === 'number' ? 'px' : ''};
                  `}
                >
                  <source src={src} type={file?.mime} />
                </video>
              ) : (
                () => {
                  throw Error(`Unknown media type ${type} for file ${src}`)
                }
              )}
              {isLoading ? (
                <CircularProgress
                  css={css`
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    margin-top: -20px;
                    margin-left: -20px;
                  `}
                />
              ) : null}
            </>
          ),
        })}
      </Grid>
    </>
  )
}
