import { ApiPosts } from '@benkrejci/shared/src/api/api'
import { Masonry } from '../layout/Masonry'
import { LoadingBox } from '../../utility/LoadingBox'
import { getPostUrl } from '@benkrejci/shared/src/api/route'

export const PostMasonryInner = ({
  isLoading,
  widget,
  posts,
}: {
  isLoading: boolean
  widget: ApiPosts
  posts: ApiPosts['posts']
}) =>
  isLoading ? (
    <Masonry
      items={new Array(5).fill(0).map((_zero, index) => ({
        file: {
          width: 1,
          height: 1,
          hash: index.toString(),
        },
        viewUrl: '',
        render: () => <LoadingBox isLoading />,
      }))}
    />
  ) : (
    <Masonry
      items={
        posts?.map((item) => {
          if (item.cover === undefined) {
            throw new Error(
              'Posts in a Posts widget with viewType=masonry must all have a cover',
            )
          }
          return {
            file: item.cover,
            viewUrl: getPostUrl(item),
          }
        }) ?? []
      }
    />
  )
