import React, { Suspense } from 'react'

import { Box, Grid, Paper } from '@mui/material'

import { WrapIf } from '../../utility/WrapIf'
import { Media } from '../Media'
import { PostGrid } from './PostGrid'
import { PostList } from './PostList'
import { RichText } from './RichText'
import { Socials } from './Socials'
import { TimelineWidget } from './TimelineWidget'
import { ApiSite, ApiWidget } from '@benkrejci/shared/dist/api/api'
import { staticAssertNever } from '@benkrejci/shared/dist/utilityTypes'
import { getResponsiveSpace } from '../../style/global'
import { Gallery } from './gallery/Gallery'
import { Posts } from './Posts'

export function Widgets({
  site,
  children,
  gridItem,
  className,
}: {
  site: ApiSite
  children: ApiWidget[] | undefined
  gridItem?: boolean
  className?: string
}): React.ReactNode {
  if (!children?.length) return null
  return (
    <Grid item={gridItem} container spacing={getResponsiveSpace()} className={className}>
      {children?.map((widget, index) => (
        <Grid item xs={12} key={`${widget.id}-${index}`}>
          <WrapIf
            if={'paper' in widget && widget.paper}
            wrap={(children, ref) => (
              <Paper elevation={24} ref={ref}>
                <Box p={getResponsiveSpace(1.5)}>{children}</Box>
              </Paper>
            )}
          >
            {() => (
              <Suspense fallback={null} key={widget.id}>
                {(() => {
                  switch (widget.__component) {
                    case 'widget.post-list':
                      return <PostList widget={widget} />

                    case 'widget.posts':
                      return <Posts widget={widget} />

                    case 'widget.post-grid':
                      return <PostGrid widget={widget} />

                    case 'widget.rich-text-block':
                      return <RichText {...widget} />

                    case 'widget.socials':
                      return <Socials socials={site.socials!} wide />

                    case 'widget.image':
                      return (
                        <Paper>
                          <Media
                            file={widget.image!}
                            showFormat="original"
                            maxHeight="60vh"
                          />
                        </Paper>
                      )

                    case 'widget.links':
                      return <>links placeholder</>

                    case 'widget.contact-form':
                      const ContactForm = React.lazy(() => import('./ContactForm'))
                      return <ContactForm widget={widget} />

                    case 'widget.timeline':
                      return <TimelineWidget widget={widget} />

                    case 'widget.gallery':
                      return <Gallery widget={widget} />

                    default:
                      return staticAssertNever(widget)
                  }
                })()}
              </Suspense>
            )}
          </WrapIf>
        </Grid>
      ))}
    </Grid>
  )
}
